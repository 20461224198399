import tw from "twin.macro"
import Modal from "../Modal/Registration"
import ButtonGroup from "../Buttons/ButtonGroup"
import EmailIcon from "../Icons/icon_email.png"
import { AuthContext } from "../../hooks/useAuth"
import { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { authCheck, isBrowser } from "../../helpers"
import ErrorNotice from "../ErrorNotice/ErrorNotice"
import { Button } from "../Buttons/Button"

const Primary: React.FC<{}> = () => {
  authCheck()
  const { state, dispatch }: any = useContext(AuthContext)
  const [email, setEmail] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)
  const [validate, setValidate] = useState({
    email: email?.toLowerCase(),
    code: ""
  })
  useEffect(()=>{
    return setEmail(localStorage.getItem("email"))
  },[])
    
  const updateForm = (e: any) => {
    setValidate({
      ...validate,
      [e.target.name]: e.target.value,
    })
  }

  const handleResend = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)

    fetch(
      `${
        process.env.GATSBY_API_ROOT_URL
      }/auth/send-email-validation-email?Email=${encodeURIComponent(
        email?.toLowerCase()
      )}`,
      {
        method: "GET",
        headers: {
          accept: "*/*",
          authorization: `Bearer ${state?.token}`,
        },
      }
    )
      .then(data => {
        if (data.status == 200) {
          setInterval(() => {
            setIsSubmitting(false)
          }, 3000)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

 
  const handleSubmit= async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)
    setError(null)

    if (Object.values(validate).some(x => (x === null || x === ''))){
      setError("All fields are required.")
      setIsSubmitting(false)
    } else {

    try {
      const res = await fetch(`${process.env.GATSBY_API_ROOT_URL}/auth/users/set-email-validation`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${state?.token}`
      },
      body: JSON.stringify({
        email: email,
        code: validate.code,
      })
    })
    .then(data => {
      if (data.status == 200) {
        setIsSubmitting(false)
        localStorage.removeItem("status")
        localStorage.removeItem("email")
        navigate("/")
    } else {
      setIsSubmitting(false)
      setError("Invalid code. Please try again.")
    }})
  } catch (error) {
    setIsSubmitting(false)
    setError(
      "Something went wrong. Please try again. " + JSON.stringify(error)
    )
    console.error(error)
  }}
  }



  return (
    <Modal
      blue={true}
      open={true}
      modalCss={[tw`w-screen lg:max-w-7xl hidden lg:flex`]}
    >
      <div css={[tw`mx-auto px-28 py-16 bg-red-300 max-w-4xl mb-5 `]}>
        <h6 css={[tw`text-white text-center text-xl mb-5`]}>
          Please Verify Your Email
        </h6>
        <img
          src={EmailIcon}
          alt="Send Email Icon"
          css={[tw` h-12 mx-auto mb-5`]}
        />
        <div>
          <h5 css={[tw`text-white text-center mb-8`]}>
            Check your email for verification code
          </h5>
        </div>
        <div
            className="input-container"
            css={[tw`relative mx-auto flex flex-col pb-8 w-72`]}
          >
            <input
              className="input"
              type="password"
              placeholder="Email Address"
              value={validate.code}
              name="code"
              onChange={updateForm}
              required
            />
            <label htmlFor="code">Code</label>
          </div>
          <p css={[tw`text-sm text-center text-white mx-auto pb-5`]}>
              Didn't recieve an email? <span css={[tw`underline cursor-pointer hover:text-red-700`]} onClick={handleResend}>Resend</span>
            </p>
            {error && <ErrorNotice error={error} />}

        <div css={[tw`flex justify-center`]}>
        <ButtonGroup
          buttons={[
            {
              value: "OK",
              onClick: handleSubmit,
              dark: true,
              disabled: isSubmitting,
              eventName: "click_validate_email",
              eventMessage: "validate the email address (user id)",
            },
            {
              value: "Cancel",
              to: "/",
              secondary: true,
              loading: isSubmitting,
              eventName: "click_cancel_validate",
              eventMessage: "validate the email address (user id)",
            },
          ]}
        />
        </div>
      </div>
    </Modal>
  )
}

export default Primary
