import { PageProps } from "gatsby"
import ValidateEmail from "../components/ValidateEmail"
import Seo from "../components/SEO"

const ValidateEmailPage: React.FC<PageProps<any, any>> = ({location}) => {
  return (
    <div>
       <Seo title="Validate Email" />
      <ValidateEmail.Primary />
      <ValidateEmail.Mobile />
    </div>
  )
}

export default ValidateEmailPage
