import { Link } from "gatsby"
import tw from "twin.macro"
import Loader from "../Loader/Loader"
import { Button, ButtonLink } from "./Button"
import { ButtonGroupProps } from "./buttons.d"

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons: [button1, button2],
}) => {
  return (
    <div css={[tw`flex flex-row justify-center gap-4`]}>
      {button1.to ? (
        <ButtonLink {...button1}>{button1.value}</ButtonLink>
      ) : (
        <Button {...button1}>{button1.value}</Button>
      )}
      {button2.to ? (
        <ButtonLink {...button2}>{button2.value}</ButtonLink>
      ) : (
        <Button {...button2}>{button2.value}</Button>
      )}
    </div>
  )
}

export default ButtonGroup
