import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import { ModalProps } from "./Modal.d"
import MobileBG from "../../images/mobile-background.jpg"
// import MobileBG from "../../images/one_sheeter_4_blue.png"
import Logo from "../Logo"
import { Link } from "gatsby"

const Modal: React.FC<ModalProps> = ({
  children,
  modalCss,
  ...remainingProps
}) => {



  return (
    <div
      css={[
        tw`overflow-y-scroll md:overflow-y-auto lg:px-10 z-40 fixed top-0 left-0 w-full h-full justify-center pointer-events-auto  bg-cover bg-center bg-top`,`background-image: url(${MobileBG})`,
      ]}
    >
      <div>
        <div css={[tw`flex flex-row justify-between items-center py-8 `]}>
          <Link
            css={[tw`font-semibold cursor-pointer col-span-1 mx-auto lg:mx-0 lg:col-span-2 z-50`]}
            to="/"
          >
            <Logo />
          </Link> 
        </div>
        <div
          css={[
            tw`flex flex-row lg:p-16 justify-center lg:mt-20 lg:w-full relative max-w-5xl mx-auto`,
            ...modalCss,
          ]}
        >
          <div>{children}</div>
        </div>
      </div>
      
      </div>
  )
}

Modal.defaultProps = {
  modalCss: [],
}

export default Modal
