import React from "react"
import tw from "twin.macro"

const ErrorNotice: React.FC<{ error: string }> = ({ error }) => {
  return (
    <p
      css={[
        tw`p-2 pl-12 border border-red-600 bg-red-100  text-red-600 rounded-lg relative`,
      ]}
    >
      <div
        css={[
          tw`text-white font-bold rounded-full bg-red-600 w-5 h-5 flex items-center justify-center mr-3 text-center absolute left-4 top-2`,
        ]}
      >
        !
      </div>
      {error}
    </p>
  )
}

export default ErrorNotice

